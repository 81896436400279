<template>
  <div class="col-md-12">
    <b-tabs v-model="activeTab" card>
      <!-- Settings Tab -->
      <b-tab title="Settings">
        <div class="form-group">
          <label>Allow Reservation</label>
          <b-form-radio v-model="reservation" value="1">Yes</b-form-radio>
          <b-form-radio v-model="reservation" value="0">No</b-form-radio>
          <span class="form-text text-muted">Select yes if you would like to enable reservations.</span>
        </div>
    
        <template v-if="reservation != 0">
          <div class="form-group">
            <label>Reservation Time (In Minutes)</label>
            <input type="number" class="form-control" v-model="time_for_tablebooking" placeholder="Number of Minutes" />
            <span class="form-text text-muted">Enter the number of minutes for each reservation.</span>
          </div>

          <div class="form-group">
            <label>Enable online payment on table booking</label>
            <b-form-radio v-model="reservation_payment_enabled" value="0">No</b-form-radio>
            <b-form-radio v-model="reservation_payment_enabled" value="1">Yes</b-form-radio>
            <span class="form-text text-muted">Enable online payment for table booking.</span>
          </div>

          <div class="form-group" v-if="reservation_payment_enabled != 0">
            <label>Minimum Reservation Amount in {{ currency }}</label>
            <input type="number" class="form-control" v-model="min_reservation_amount" placeholder="Enter Amount" />
            <span class="form-text text-muted">Enter the minimum amount ({{ currency }}) for each reservation.</span>
          </div>
        </template>

        <hr />
        <div class="form-group text-right">
          <b-button class="bg-primary text-light" @click="save">Save</b-button>
        </div>
      </b-tab>

      <!-- Tables Management Tab -->
      <b-tab title="Tables">
        <h2>Tables</h2>
        <b-button class="mb-3 bg-primary text-light" style="float:right" @click="openModal(null)">Add Table</b-button>

        <div v-if="tables.length">
          <b-table :items="tables" :fields="tableFields" responsive="sm">
            <template #cell(actions)="data">
              <i class="fas fa-edit text-warning mr-2" style="cursor: pointer;" @click="openModal(data.item)"></i>
              <i class="fas fa-trash-alt text-danger" style="cursor: pointer;" @click="deleteTable(data.item.id)"></i>
            </template>
          </b-table>
        </div>
        <div v-else class="text-center py-3">
          <p class="mb-0 text-danger">No tables added yet. Click "Add Table" to create one.</p>
        </div>

        <b-modal v-model="showModal" title="Add/Edit Table" hide-footer>
          <div class="form-group">
            <label>Title</label>
            <input type="text" class="form-control" v-model="form.title" placeholder="Table Title" />
          </div>
          <div class="form-group">
            <label>Seating Capacity</label>
            <input type="number" class="form-control" v-model="form.seating_capacity" placeholder="Seating Capacity" />
          </div>
          <b-button class="bg-primary text-light" @click="saveTable">Save</b-button>
        </b-modal>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { GENERAL_SETTINGS, ADD_TABLE, GET_GENERAL_SETTINGS } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "ReservationSettings",
  data() {
    return {
      activeTab: 0, // To manage the currently active tab (0 = first tab, 1 = second tab)
      settings: [],
      reservation: 0,
      time_for_tablebooking: 0,
      reservation_payment_enabled: 0,
      min_reservation_amount: 0,
      tables: [],
      showModal: false,
      currency: null,
      form: { id: null, title: "", seating_capacity: "" },
      tableFields: [
        { key: "title", label: "Title" },
        { key: "seating_capacity", label: "Seating Capacity" },
        { key: "actions", label: "Actions", class: "text-center" },
      ],
    };
  },
  methods: {
    getGeneralSettings() {
      this.$store
        .dispatch(GET_GENERAL_SETTINGS)
        .then((resp) => {
          this.settings = resp;
          this.settings.forEach((setting) => {
            if (setting.key === "reservation") this.reservation = setting.value;
            if (setting.key === "min_reservation_amount") this.min_reservation_amount = setting.value;
            if (setting.key === "time_for_tablebooking") this.time_for_tablebooking = setting.value;
            if (setting.key === "reservation_payment_enabled") this.reservation_payment_enabled = setting.value;
            if (setting.key === "currency") this.currency = setting.value;
          });
        })
        .catch((error) => {
          if (error.status === "Error") {
            Swal.fire("Error", error.message, "error");
            return;
          }
          console.log(error);
        });
    },
    save() {
      this.$store
        .dispatch(GENERAL_SETTINGS, {
          reservation: this.reservation,
          time_for_tablebooking: this.time_for_tablebooking,
          reservation_payment_enabled: this.reservation_payment_enabled,
          min_reservation_amount: this.min_reservation_amount,
        })
        .then((resp) => Swal.fire("Information!", resp.message, resp.status === "Success" ? "success" : "error"))
        .catch((error) => Swal.fire("Error", error.message, "error"));
    },
    getTables() {
      ApiService.get("business/settings/get-tables")
        .then(({ data }) => (this.tables = data))
        .catch((error) => Swal.fire("Error", error.message, "error"));
    },
    openModal(table) {
      this.form = table ? { ...table } : { id: null, title: "", seating_capacity: "" };
      this.showModal = true;
    },
    saveTable() {
      const endpoint = this.form.id
        ? `business/settings/update-table/${this.form.id}`
        : "business/settings/add-table";

      ApiService.post(endpoint, this.form)
        .then(({ data }) => {
          Swal.fire("Information!", data.message, "success");
          this.getTables();
          this.showModal = false;
        })
        .catch((error) => Swal.fire("Error", error.message, "error"));
    },
    deleteTable(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete(`business/settings/delete-table/${id}`)
            .then(({ data }) => {
              Swal.fire("Deleted!", data.message, "success");
              this.getTables();
            })
            .catch((error) => Swal.fire("Error", error.message, "error"));
        }
      });
    },
  },
  mounted() {
    
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Business Settings" },
      { title: "Reservation Setting" }
    ]);
  
    this.getGeneralSettings();
    this.getTables();
  },
};
</script>
